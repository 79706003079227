<template>
  <div class="main">
    <van-nav-bar
        title="现场检查表"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
    />
    <div class="main-content">
      <div v-for="(item, index) in typeArr" :key="index" style="padding: 20px">
        <template  v-if="page == index + 1">
          <div class="jian"></div>
          <div class="title left">{{ item.name }}</div>
          <div class="list">
            <div class="jian"></div>
            <div class="item" v-for="(dataItem,index) in dataArr[item.code]" :key="index">
              <div class="left" style="padding-top: 10px;padding-bottom: 10px">{{dataItem.code}}</div>
              <div>
                <van-row type="flex" align="center">
                  <van-col span="20" >
                    <div class="left">{{dataItem.content}}</div>
                    <p style="font-size: 12px;color: #666;text-align: left;">{{ dataItem.remark }}</p>
                  </van-col>
                  <van-col span="4" >
                    <div class="gou" @click="check(dataItem)">
                      <div v-if="dataItem.active" style="color: #2B8DCE;font-size: 1.7rem"><van-icon name="checked" /></div>
                      <div v-if="dataItem.active" style="font-size: .875rem;color: #2B8DCE">符合</div>
                      <div v-if="!dataItem.active" style="color: #cec9c9;font-size: 1.7rem"><van-icon name="checked" /></div>
                      <div v-if="!dataItem.active" style="font-size: .875rem;color: #cec9c9">未符合</div>
                    </div>
                  </van-col>
                </van-row>
                <!-- {{ dataItem.score }} -->
                <div v-if="!dataItem.active">
                  <van-field v-if="dataItem.scoreType == 1" type="digit" label="扣除分值" placeholder="请输入具体扣除分值">
                    <template #input>
                      <van-stepper v-model="dataItem.score" :step="Math.abs(dataItem.oldScore)" :decimal-length="1" input-width="80px" min="0" max="100" />&emsp;分
                    </template>
                  </van-field>
                  <!-- <span>分</span> -->
                </div>
                <div v-if="!dataItem.active">
                  <van-field v-model="dataItem.other" type="textarea" maxlength="50" show-word-limit placeholder="请输入具体情况说明"></van-field>
                </div>
              </div>
              <van-divider />
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 最后一页  签名 -->
    <div v-if="page < this.typeArr.length + 1" style="margin-top: 10px;"><van-button plain type="info" style="width: 100%;border: 0;" @click="goPage(page+1)">下一页</van-button></div>
    <div class="content" v-if="page == this.typeArr.length + 1">
      <van-field label="上传检查图片" label-class="bold-font" />
      <van-uploader v-model="healthList" :before-read="beforeRead" :after-read="healthAfterRead" multiple :max-count="5"
                    style="display: unset;margin-bottom: 1rem;margin-top: 2rem" />
      <p style="font-size: 12px;color: #999;text-align: left;">请上传 1MB 以内的 jpg 格式图片，最多上传 5 张</p>
      <van-field
          v-model="remark"
          rows="2"
          autosize
          label="备注"
          label-width="60"
          label-class="bold-font"
          type="textarea"
          maxlength="100"
          placeholder="请输入备注"
          show-word-limit
          style="margin-bottom: 1rem;margin-top: 2rem"
      />
      <div class="left" style="margin-bottom: 1rem">被检查单位（负责人)(签字）</div>
      <div class="signbox" @click="sign(1)"><span v-if="!post.storeSign">点击签名</span><img :src="post.storeSign"  v-if="post.storeSign" width="100%" height="150"></div>
      <div class="left" style="margin-bottom: 1rem;margin-top: 2rem">以上内容经核对无误</div>
      <div>
        <van-row type="flex" align="center" gutter="20">
          <van-col span="12">
            <div class="qianzi">组长 (签字）</div>
            <div class="signbox"  @click="sign(2)"><span v-if="!post.firstSign">点击签名</span><img :src="post.firstSign"  v-if="post.firstSign" width="100%" height="150"></div>
          </van-col>
          <van-col span="12" >
            <div class="qianzi">检查人员（签字）</div>
            <div class="signbox"  @click="sign(3)"><span v-if="!post.secondSign">点击签名</span><img :src="post.secondSign"  v-if="post.secondSign" width="100%" height="150"></div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div v-if="page == this.typeArr.length + 1"><van-button type="primary" @click="ok()" style="margin-top: 10px;width: 100%;border: 0;background-color: #2f85da;">提交</van-button></div>
    <van-popup v-model="show" closeable>
      <div class="sign">
        <div style="height: 10px;"></div>
        <button @click="handleReset">清空</button> <button @click="handleGenerate">确定签名</button>
        <div style="height: 50px;"></div>
        <div style="border: 2px solid #E6E6E6 ;background-color: white;width: 90vw;height:200px">
          <vue-esign ref="esign"  :width="800" :height="500" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getScore,getUpload,getTaskOk, taskTypeInfoApi } from '@/api/get'
import Vue from 'vue';
import {NavBar, Col, Row, Icon, Cell, Button, Popup, Toast, Divider, Uploader, field, Stepper } from 'vant';
import vueEsign from 'vue-esign'
import {taskList} from "../../api/get";
Vue.use(vueEsign)
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Divider)
Vue.use(Uploader)
Vue.use(field)
Vue.use(Stepper)

export default {
  name: "taskHand",
  data(){
    return{
      taskId:0,
      post:{
        "id": "", //任务id
        "storeSign": "", //药店签名
        "firstSign": "", //检查人员一签名
        "secondSign": "", //检查人员二签名
        'remark': '',
        'pics': '',
        "items": [
          {
            "scoreId": "", //分值id（现场检查表数据）
            "regular": true //是否合格：true合格，false不合格
          }
        ]
      },
      signid:1,
      page:1,
      show:false,
      arr10:[],
      arr20:[],
      arr30:[],
      arr40:[],
      arr50:[],
      arr60:[],
      arr70:[],
      arr80:[],
      lineWidth: 6,
      lineColor: 'blue',
      bgColor: '',
      resultImg: '',
      isCrop: false,
      healthList: [],
      remark: '',
      // 分类信息
      typeArr: [],
      // 数据集合
      dataArr: {}
    }
  },
  created() {
    this.post.id=this.$route.query.id
    this.taskId=this.$route.query.taskId
  },
  mounted() {
    this.loadTypeData()
  },
  methods:{
    loadMetaInfo() {
      window.aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'BLOCK']
      })
      // 单页应用 或 “单个页面”需异步补充PV日志参数还需进行如下埋点：
      window.aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-waiting', 'MAN']
      });//
      // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
      window.aplus_queue.push({
        'action':'aplus.sendPV',
        'arguments':[{
          is_auto: false
        }, {
          // 当前你的应用信息，此两行请勿修改
          sapp_id: '21436',
          sapp_name: 'yaojian',
          // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
          x: 111,
          y: 222,
          page_id: 'begin',
          page_name: '现场检查表',
          page_url: '/begin'
        }]
      })
      window.aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'START']
      })
    },
    loadTypeData() {
      taskTypeInfoApi({ taskId: this.$route.query.id }).then(res => {
        // this.typeArr = res.data.data
        Object.keys(res.data.data).forEach(key => {
          if(key==100){
            this.typeArr.push({ code: key, name: res.data.data[key] })
            this.loadDataByType(key)
          }
        })
        console.log(this.typeArr)
      })
    },
    beforeRead(file) {
      console.log('file', file)
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$toast('请上传 jpg 格式图片')
        return false
      }
      return true
    },
    healthAfterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      getUpload({
        imageData: file.content
      }).then(res => {
        console.log('res.code', res)
        if (res.data.code == 200) {
          file.status = 'success'
          file.message = '上传成功'
          this.healthList[detail.index]['url'] = res.data.data
          console.log('healthList', this.healthList)
        } else {
          file.status = 'failed'
          file.message = '上传失败'
        }
      })
    },
    ok(){
      var sumData = [];
      const tempData = {}
      Object.keys(this.dataArr).forEach(key => {
        if (this.dataArr[key].length > 0) {
          tempData[key] = this.dataArr[key].map(function (item) {
            return { other: item.other, scoreId: item.id, regular: item.active ? true : false, score: item.score };
          })
        }
        sumData = sumData.concat(tempData[key])
      })
      // var sumData = [];
      // sumData= sumData.concat(arr10).concat(arr20).concat(arr30).concat(arr40).concat(arr50).concat(arr60).concat(arr70).concat(arr80) ;
      let params= Object.assign({}, this.post)
      params.items = sumData
      params.remark = this.remark
      params.pics = JSON.stringify(this.healthList.map(x => x.url))
      if(!this.post.storeSign||!this.post.firstSign||!this.post.secondSign){
        Toast("请签名")
        return
      }
      getTaskOk(params).then(res => {
        if (res.data.code === 200){
          Toast("已提交")
          this.$router.push({ path: '/today', query: {id:1}})
        }
      })
    },
    sign(id) {
      this.signid=id
      this.show = true;
    },
    handleReset () {

      this.$refs.esign.reset()

    },
    // 生成base64格式
    handleGenerate () {
      this.$refs.esign.generate().then(res => {
        getUpload({imageData:res}).then(res => {
          if (res.data.code === 200){
            Toast("签名成功")
            this.resultImg =res.data.data
            if(this.signid==1){
              this.post.storeSign=this.resultImg
            }
            if(this.signid==2){
              this.post.firstSign=this.resultImg
            }
            if(this.signid==3){
              this.post.secondSign=this.resultImg
            }
          }
        })


        this.handleReset ()
        this.show = false;
      }).catch(err => {
        console.log(err) // 画布没有签字时会执行这里
        Toast("未签字")

      })
    },
    goPage(p){
      // console.log('p', p, this.typeArr[p - 1].code, this.dataArr[this.typeArr[p - 1].code].length, this.typeArr.length)
      if (this.typeArr[p - 1] && this.dataArr[this.typeArr[p - 1].code].length == 0 && p < this.typeArr.length) {
        this.goPage(p + 1)
        return false
      }
      // const scrollTopObj =
      // document.documentElement.scrollTop || document.body.scrollTop
      // console.log('scrollTopObj', scrollTopObj)
      // if (scrollTopObj > 0) {
      //   //回调 backtop()函数 直到不符合条件
      //   window.requestAnimationFrame(this.goPage(p))
      //   // 滚动动画效果 动画没执行完之前继续滑动屏幕会有点bug
      //   window.scrollTo(0, scrollTopObj - scrollTopObj / 8);
      //   // 没有动画效果方式  这种方式可以不调用requestAnimationFrame();
      //   //window.scrollTo(0, 0);
      // }
      window.scrollTo(0, 0)
      this.page=p

    },
    check(x){
      this.$set(x,'active',!x.active)
    },
    onClickLeft() {
      this.$router.push("/today")
    },
    loadDataByType(type) {
      let data={taskId:this.taskId}
      taskList(data).then(res => {
        if (res.data.code === 200){
          const tempData = res.data.data
          for(let i in tempData){
            this.$set(tempData[i], 'active', true)
            this.$set(tempData[i], 'oldScore', tempData[i].score)
            this.$set(tempData[i], 'score', Math.abs(tempData[i].score))
          }
          this.$set(this.dataArr, type, tempData)
        }
      })
/*      getScore({type:type}).then(res => {
        if (res.data.code === 200){
          const tempData = res.data.data
          for(let i in tempData){
            this.$set(tempData[i], 'oldScore', tempData[i].score)
            this.$set(tempData[i], 'score', Math.abs(tempData[i].score))
          }
          // this.dataArr[type] = res.data.data
          this.$set(this.dataArr, type, tempData)
        }
      })*/
    },
    loadData () {
      getScore({type:10}).then(res => {
        if (res.data.code === 200){
          this.arr10=res.data.data
          for(let i in this.arr10){
            this.$set(this.arr10[i], 'active', true)
          }
        }
      })
      getScore({type:20}).then(res => {
        if (res.data.code === 200){
          this.arr20=res.data.data
          for(let i in this.arr20){
            this.$set(this.arr20[i], 'active', true)
          }
        }
      })
      getScore({type:30}).then(res => {
        if (res.data.code === 200){
          this.arr30=res.data.data
          for(let i in this.arr30){
            this.$set(this.arr30[i], 'active', true)
          }
        }
      })
      getScore({type:40}).then(res => {
        if (res.data.code === 200){
          this.arr40=res.data.data
          for(let i in this.arr40){
            this.$set(this.arr40[i], 'active', true)
          }
        }
      })
      getScore({type:50}).then(res => {
        if (res.data.code === 200){
          this.arr50=res.data.data
          for(let i in this.arr50){
            this.$set(this.arr50[i], 'active', true)
          }
        }
      })
      getScore({type:60}).then(res => {
        if (res.data.code === 200){
          this.arr60=res.data.data
          for(let i in this.arr60){
            this.$set(this.arr60[i], 'active', true)
          }
        }
      })
      getScore({type:70}).then(res => {
        if (res.data.code === 200){
          this.arr70=res.data.data
          for(let i in this.arr70){
            this.$set(this.arr70[i], 'active', true)
          }
        }
      })
      getScore({type:80}).then(res => {
        if (res.data.code === 200){
          this.arr80=res.data.data
          for(let i in this.arr80){
            this.$set(this.arr80[i], 'active', true)
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="css" src="./assets/index.css" />